import React from "react"
import Layout from "../../components/layout"
import CommentsFB from "../../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Poemas = () => {
  return (
    <Layout>
      <GatsbySeo
        title="Asombro - Poemas"
        titleTemplate="%s | Eduardo Ceballos"
        description="Asombro"
        canonical="https://eduardoceballos.com/poemas/asombro"
        openGraph={{
          url: "https://eduardoceballos.com/poemas/asombro",
          title: "Asombro - Poemas",
          description: "Asombro",
          images: [
            {
              url: "https://eduardoceballos.com/seo/poemas.jpg",
              alt: "Poemas",
            },
          ],
          site_name: "Eduardo Ceballos",
        }}
        twitter={{
          handle: "@PoetadeSalta",
          site: "@PoetadeSalta",
          cardType: "summary_large_image",
        }}
      />
      <h1>Asombro</h1>
      <center>
        <p>
          ¿Cómo hablarte para que entiendas,
          <br />
          qué palabras usar
          <br />
          para rozar tu interés ?<br />
          Te obsequiaré una canción
          <br />
          que nombre todos tus mares,
          <br />
          tus mapas, tus cielos, tu raza,
          <br />
          tu pueblo, tus ojos,
          <br />
          con lágrima nueva
          <br />
          de asombro
          <br />
          por donde sientas
          <br />
          todo el peso de la existencia,
          <br />
          gota que se mete,
          <br />
          arremolinándose
          <br />
          por el agujero más secreto de la vida.
          <br />
          &nbsp;
          <br />
          Una flor de vivos colores
          <br />
          le pone fragancia a una porción de tiempo
          <br />
          tan breve como una sonrisa,
          <br />
          espejo y fuego,
          <br />
          agua plural y danza.
          <br />
          &nbsp;
          <br />
          Una lluvia viene desde abajo,
          <br />
          desde adentro de mi mundo,
          <br />
          interior, solo y auténtico,
          <br />
          para repartirme con el viento
          <br />
          en un gesto de luz sideral
          <br />
          que viaja desde siempre
          <br />
          por aquí y por allá,
          <br />
          por todas las distancias.
          <br />
          &nbsp;
          <br />
          Suelto mis vibrantes energías
          <br />
          para empujar los espacios
          <br />y movilizar los ángulos
          <br />
          de compartir con los pájaros y los ríos
          <br />
          este cauce de sangre junto a niños
          <br />
          que traen la memoria siempre incesante
          <br />
          de otros niños
          <br />
          en un interminable cadena de instantes
          <br />
          que de tan de uno,
          <br />
          se hacen multitudes
          <br />
          que van y vienen con la palabra
          <br />
          construyendo la historia
          <br />
          con la paloma y con el arte
          <br />
          que transporta el ser hacia otras dimensiones.
          <br />
          &nbsp;
          <br />
          Tomo un vocablo encontrado
          <br />
          en el diccionario del tiempo,
          <br />
          descubro los golpes para cincelar
          <br />
          su sentido último y primero,
          <br />
          una palabra para silabear el silencio,
          <br />
          que baja misterioso por un río interno,
          <br />
          que mueve la sangre y emociona.
          <br />
          &nbsp;
          <br />
          El agua desorienta,
          <br />
          sube en el sur, en el norte baja
          <br />
          como un mandato sublime
          <br />
          de la física mundana.
          <br />
          <br />
          Vuelvo a tu instante,
          <br />
          punto del ahora permanente,
          <br />
          para cantarte con el sol a cuestas
          <br />
          mirarte para adentro
          <br />
          con el corazón que grita
          <br />
          retumbo hueco, mensaje nuevo
          <br />
          que trae tu geografía, tan primavera,
          <br />
          que llena la tierra de semillas.
          <br />
        </p>
      </center>
      <div class="autores">
        <div class="autor">
          <h6>Eduardo Ceballos.</h6>
          <img
            src="https://res.cloudinary.com/lagexpress/image/upload/f_auto,q_auto,r_max,q_100,w_100,h_100//strapi/Foto_a321272643"
            alt=""
          />
        </div>
      </div>
      <CommentsFB></CommentsFB>
    </Layout>
  )
}

export default Poemas
